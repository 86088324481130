
  .seat {
    background-color: white !important;
    border: 1px solid teal !important;
  }

  .seat .seat__number {
    color: black !important;
  }
  
  .seat--reserved {
    background-color: gray !important;
  }
  
  .seat--selected {
    background-color: teal !important;
  }
  .seat--selected .seat__number {
    color: white !important;
  }
  
  .seat-picker {
    margin: auto !important;
  }

  .seat-content {
    overflow: scroll !important;
  }

/*   .chakra-ui-dark .seat-content .loader {
  } */
/*   .seat-content .loader {
  }
  
  .seat-picker__row {
  }
  
  .seat-picker__row__number {
  } */
  
  div.blank {
    display: none !important;
  }
  
  